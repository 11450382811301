import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between align-center" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "headline" }
const _hoisted_4 = { class: "subtitle-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_slide_y_transition = _resolveComponent("v-slide-y-transition")!

  return (_openBlock(), _createBlock(_component_v_card, {
    onClick: _ctx.onClick,
    class: "pa-3 ma-1 d-flex flex-column",
    elevation: "2",
    outlined: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_4, "Total: " + _toDisplayString(_ctx.count), 1)
        ]),
        (_ctx.withControls)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              icon: "",
              onClick: _withModifiers(_ctx.toggleControls, ["stop"]),
              variant: "flat"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-cog")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_v_slide_y_transition, null, {
        default: _withCtx(() => [
          (_ctx.withControls && _ctx.showControls)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "mt-2 py-1",
                outlined: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                variant: "solo",
                                items: _ctx.units,
                                modelValue: _ctx.selectedUnit,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUnit) = $event)),
                                label: "Unit",
                                dense: "",
                                outlined: "",
                                onChange: _ctx.fetchData
                              }, null, 8, ["items", "modelValue", "onChange"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: "solo",
                                type: "number",
                                modelValue: _ctx.duration,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.duration) = $event)),
                                label: "Duration",
                                dense: "",
                                outlined: "",
                                onInput: _ctx.fetchData
                              }, null, 8, ["modelValue", "onInput"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}