
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import SquareButton from './SquareButton.vue';
  import UserTableComponent from './UserTableComponent.vue';
  import { useToken } from '../auth';
  import { Configuration, StatsApiFactory, StatsActiveUsersGetUnitEnum} from '../../api';

  export default defineComponent({
    components: {
      SquareButton,
      UserTableComponent,
    },
    setup() {
      const currentEndpoint = ref('');
      const totalWithFullProfiles = ref(0);
      const totalWithoutProfile = ref(0);
      const totalWithoutTests = ref(0);
      const totalActive = ref(0);
      const totalInactive = ref(0);
      const totaTopChaters = ref(0);
      const componentKey = ref(0);
      const currentUnit = ref(String(StatsActiveUsersGetUnitEnum.Hours));
      const currentDuration = ref(10);

      const buttons = computed(() => [
        { title: 'With Full Profile', count: totalWithFullProfiles.value, endpoint: 'with-full-profile' },
        { title: 'Without Profile', count: totalWithoutProfile.value, endpoint: 'without-profile' },
        { title: 'Without Tests', count: totalWithoutTests.value, endpoint: 'without-tests' },
        { title: 'Active', count: totalActive.value, endpoint: 'active-users', withControls: true},
        { title: 'Inactive', count: totalInactive.value, endpoint: 'inactive-users', withControls: true},
        { title: 'Top Chaters', count: totaTopChaters.value, endpoint: 'top-chatters' },
      ]);
      const token = useToken();
      const config = new Configuration({apiKey: token as string});
      const statsApi = StatsApiFactory(config);

  
      const setCurrentEndpoint = (endpoint: string) => {
        console.log('setCurrentEndpoint', endpoint);
        currentEndpoint.value = endpoint;
        componentKey.value++;
      };

      const handleRefresh = ({endpoint, unit, duration}) => {
        componentKey.value++;
        currentEndpoint.value = endpoint;
        currentUnit.value = unit;
        currentDuration.value = duration;
        console.log('handleRefresh', endpoint, unit, duration);
        switch (currentEndpoint.value) {
          case 'active-users':
            getActiveUsers('', duration, unit as StatsActiveUsersGetUnitEnum);
            break;
          case 'inactive-users':
            getInactiveUsers('', duration, unit as StatsActiveUsersGetUnitEnum);
            break;
          default:
            console.error('Unknown endpoint', currentEndpoint.value);
        }
      };

      const refreshData = () => {
        getUsersWithFullProfile('');
        getUsersWithoutProfile('');
        getUsersWithoutTestResult('');
        getTopChatters('');
        getActiveUsers('', 10, StatsActiveUsersGetUnitEnum.Hours);
        getInactiveUsers('', 10, StatsActiveUsersGetUnitEnum.Hours);
        componentKey.value++;
      };
  
      async function getUsersWithFullProfile(searchValue: string) {
        try {
          const response = await statsApi.statsUsersWithFullProfileGet(searchValue)
          if (response.data.data != null) {
            totalWithFullProfiles.value = response.data.total as number;
          }
        } catch (error) {
          console.error('Failed to get UsersWithFullProfile', error);
        }
      }

      async function getUsersWithoutProfile(searchValue: string) {
        try {
          const response = await statsApi.statsUsersWithoutProfileGet(searchValue)
          if (response.data.data != null) {
            totalWithoutProfile.value = response.data.total as number;
          }
        } catch (error) {
          console.error('Failed to get UsersWithoutProfile', error);
        }
      }

      async function getUsersWithoutTestResult(searchValue: string) {
        try {
          const response = await statsApi.statsUsersWithoutTestResultGet(searchValue)
          if (response.data.data != null) {
            totalWithoutTests.value = response.data.total as number;
          }
        } catch (error) {
          console.error('Failed to get UsersWithoutTestResult', error);
        }
      }

      async function getActiveUsers(searchValue: string, duration: number, unit: StatsActiveUsersGetUnitEnum) {
        try {
          const response = await statsApi.statsActiveUsersGet(duration, unit, searchValue, 0, 0)
          if (response.data.data != null) {
            totalActive.value = response.data.total as number;
          } else {
            totalActive.value = 0;
          }
        } catch (error) {
          console.error('Failed to get ActiveUsers', error);
        }
      }

      async function getInactiveUsers(searchValue: string, duration: number, unit: StatsActiveUsersGetUnitEnum) {
        try {
          const response = await statsApi.statsInactiveUsersGet(duration, unit, searchValue, 0, 0)
          if (response.data.data != null) {
            totalInactive.value = response.data.total as number;
          } else {
            totalInactive.value = 0;
          }
        } catch (error) {
          console.error('Failed to get InactiveUsers', error);
        }
      }

      async function getTopChatters(searchValue: string) {
        try {
          const response = await statsApi.statsTopChattersGet(searchValue)
          if (response.data.data != null) {
            totaTopChaters.value = response.data.total as number;
          }
        } catch (error) {
          console.error('Failed to get TopChatters', error);
        }
      }

      onMounted(() => { 
        refreshData();
      });

      return {
        currentDuration,
        currentUnit,
        handleRefresh,
        buttons,
        currentEndpoint,
        setCurrentEndpoint,
        totalWithFullProfiles,
        totalWithoutProfile,
        totalWithoutTests,
        totalActive,
        componentKey,
        refreshData,
      };
    },
  });
  