import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_SquareButton = _resolveComponent("SquareButton")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_UserTableComponent = _resolveComponent("UserTableComponent")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "gray",
                onClick: _ctx.refreshData
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Refresh")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
            return (_openBlock(), _createBlock(_component_SquareButton, {
              key: button.title,
              title: button.title,
              count: button.count,
              withControls: button.withControls,
              endpoint: button.endpoint,
              onRefresh: _ctx.handleRefresh,
              onClick: ($event: any) => (_ctx.setCurrentEndpoint(button.endpoint))
            }, null, 8, ["title", "count", "withControls", "endpoint", "onRefresh", "onClick"]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_divider)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.currentEndpoint)
        ? (_openBlock(), _createBlock(_component_UserTableComponent, {
            key: _ctx.componentKey,
            endpoint: _ctx.currentEndpoint,
            duration: _ctx.currentDuration,
            unit: _ctx.currentUnit
          }, null, 8, ["endpoint", "duration", "unit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}