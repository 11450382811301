
  import { defineComponent, ref } from 'vue';
  import { Configuration, AuthApiFactory, DefsRegisterReq } from '../../api';
  
  export default defineComponent({
    setup() {
      const email = ref('');
      const password = ref('');
      const config = new Configuration({});
      const authApi = AuthApiFactory(config);

      async function register() {
        const registerReq: DefsRegisterReq = {
            email: email.value,
            password: password.value
        };
        try {
          const response = await authApi.authRegisterPost(registerReq);
        } catch (error) {
          console.error('Registration failed:', error);
        }
      }
  
      return {
        email,
        password,
        register
      };
    }
  });
  