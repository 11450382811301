
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import {
  Configuration, UsersApiFactory,ChatApiFactory, DefsChatMessageData, DefsMatchListResponse, DefsUserData
} from '../../api';
import { useToken } from '../auth';
import {WS_URL} from '../types';

export default {
  props: {
    chatDialogVisible: Boolean,
    userID: {
      type: String,
      default: "",
    },
  },
  components: {
  },
  setup(props, { emit }) {
    const localChatDialogVisible = ref(props.chatDialogVisible);
    const token = useToken();
    const config = new Configuration({ apiKey: token as string });
    const usersApi = UsersApiFactory(config);
    const chatApi = ChatApiFactory(config);
    const chatUsers = ref<DefsMatchListResponse>({ data: [] });
    const selectedUser = ref<DefsUserData>();
    const messages = ref<DefsChatMessageData[]>([]);
    const selectedUserIndex = ref()
    const newMessage = ref("")
    const newChatUser = ref("")
    const websocket = ref<WebSocket | null>(null);
    const loading = ref(false)
    const page = ref(1);
    const pageSize = ref(10);
    const totalMessages = ref(0);
    const allMessagesLoaded = ref(false);
    const messagesContainer = ref<HTMLElement | null>(null);

    const openWebSocketConnection = () => {

      if (websocket.value) {
        websocket.value.close();
      }

      let link = `ws://${WS_URL}/ws2/${props.userID}`
      console.log(link)
      websocket.value = new WebSocket(link);
      websocket.value.onopen = function () {
        console.log('WebSocket connection opened');
        const authMessage = {
          type: 'authenticate',
          token: token,
        };
        websocket.value?.send(JSON.stringify(authMessage));
      };

      websocket.value.onerror = function (error) {
        console.error('WS Error:', error);
      };

      websocket.value.onclose = function () {
        messages.value = [];
      };

      websocket.value.onmessage = function (event) {
        const incomingMessage = JSON.parse(event.data);
        console.log('WebSocket message received:', incomingMessage);
        if (incomingMessage.d == null || incomingMessage.d.rs == null || incomingMessage.d.rs.id == null) {
          return;
        }
        console.log('selectedUser.id', selectedUser.value?.id);
        //if (selectedUser.value && incomingMessage.d.ss.id === selectedUser.value.id) {
          messages.value.push({
            is_sent_by_user: incomingMessage.d.rs.id !== props.userID,
            content: incomingMessage.d.m
          });
          nextTick(() => {
            const messagesContainer = document.getElementById('messages');
            if (messagesContainer != null) {
              messagesContainer.scrollTop = messagesContainer?.scrollHeight;
            }
          });
        //}
      };

    };

    const closeWebSocketConnection = () => {
      if (websocket.value) {
        websocket.value.close();
      }
    };

    watch(() => props.chatDialogVisible, (newVal) => {
      localChatDialogVisible.value = newVal;
      if (newVal) {
        messages.value = [];
        selectedUser.value = undefined;
      } else {
        closeWebSocketConnection();
      }
    });

    watch(() => props.userID, (newUserID) => {
      if (newUserID) {
        messages.value = [];
        chatUsers.value.data = [];
        if (props.chatDialogVisible) {
          fetchUserChatList(newUserID);
        }
      }
    }, { immediate: true });

    const closeOnEscape = (event: { key: string; }) => {
      if (event.key === 'Escape') {
        emit('update:chatDialogVisible', false);
      }
    };

    const closeDialog = () => {
      localChatDialogVisible.value = false;
      emit('update:chatDialogVisible', false);
    };


    onMounted(() => {
      window.addEventListener('keydown', closeOnEscape);
    });

    onUnmounted(() => {
      window.removeEventListener('keydown', closeOnEscape);
      closeWebSocketConnection();
      const messagesContainer = document.getElementById('messages');
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    });

    // function handleScroll(event) {
    //   const { scrollTop, offsetHeight, scrollHeight } = event.target;
    //   if (scrollTop + offsetHeight >= scrollHeight-1) {
    //     loadMoreMessages();
    //   }
    // }

    let isAtBottom = true;

    function handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const scrollThreshold = 3;
      isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
      if (scrollTop <= scrollThreshold) {
        loadMoreMessages();
      }
    }

    function loadMoreMessages() {
      page.value += 1;
      fetchMessages();
    }

    function scrollToBottom() {
      nextTick(() => {
        const messagesContainerElement = messagesContainer.value;
        if (messagesContainerElement) {
          messagesContainerElement.scrollTop = messagesContainerElement.scrollHeight;
        }
      });
    }

    async function fetchMessages() {
      loading.value = true;
      try {
        const response = await chatApi.getChatMessages(props.userID, selectedUser.value?.id as string, page.value, pageSize.value);
        if (response.data != null) {
          const newMessages = response.data.data as Array<DefsChatMessageData>;
          messages.value = [...newMessages.reverse(), ...messages.value];
          totalMessages.value = response.data.total as number;
        }
      } catch (error) {
        console.error('Failed to fetch chat messages:', error);
      } finally {
        loading.value = false;
        if (isAtBottom) {
          scrollToBottom();
        }
      }
    }


    // async function fetchMessages() {
    //   loading.value = true;
    //   try {
    //     const response = await chatApi.getChatMessages(props.userID, selectedUser.value?.id as string, page.value, pageSize.value);
    //     if (response.data != null) {
    //       messages.value = [...messages.value, ...response.data.data as Array<DefsChatMessageData>];
    //       totalMessages.value = response.data.total as number;
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch chat messages:', error);
    //   } finally {
    //     loading.value = false;
    //   }
    // }

    function selectUser(user: DefsUserData | undefined, index: number) {
      page.value = 1
      messages.value = [];
      selectedUser.value = user;
      fetchMessages().then();
      closeWebSocketConnection();
      openWebSocketConnection();
      nextTick(() => {
        const messagesContainer = document.getElementById('messages');
        if (messagesContainer) {
          messagesContainer.removeEventListener('scroll', handleScroll);
          messagesContainer.addEventListener('scroll', handleScroll);
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    }

    function sendMessage() {
      if (websocket.value && selectedUser.value && websocket.value.readyState === WebSocket.OPEN) {
        const message = {
          si: props.userID,
          ri: selectedUser.value.id,
          d: {
            m: newMessage.value
          }
        };
        websocket.value.send(JSON.stringify(message));
        newMessage.value = '';
      } else {
        console.error('WebSocket is not open. Cannot send message.');
      }
    }

    async function fetchUserChatList(user_id: string) {
      try {
        const response = await usersApi.usersUserIDMatchesGet(user_id)
        if (response.data != null) {
          chatUsers.value = response.data
        }
      } catch (error) {
        console.error('Failed to fetch chat-list:', error);
      }
    }


    return {
      selectedUser,
      newChatUser,
      sendMessage,
      newMessage,
      selectUser,
      selectedUserIndex,
      chatUsers,
      localChatDialogVisible,
      closeDialog,
      messages,
      pageSize,
      totalMessages,
      allMessagesLoaded,
      loadMoreMessages,
      messagesContainer,
    }
  }
}

