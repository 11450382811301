

  import { ref, watch} from 'vue';
  import { Header} from '@/types';
  import { Configuration, StatsApiFactory, DefsUserData, DefsOpenChat, StatsActiveUsersGetUnitEnum } from '../../api';
  import { useToken } from '../auth';
  
  
  export default {
    name: 'UserTableComponent',
    props: {
      endpoint: {
        type: String,
        required: true,
      },
      duration: {
        type: Number,
        default: 10,
      },
      unit: {
        type: String,
        default: StatsActiveUsersGetUnitEnum.Hours,
      },
    },
    setup(props, { emit }) {
      const search = ref('');
      const users = ref([] as DefsUserData[]);
      const topChatters = ref([] as DefsOpenChat[]);
      const total = ref(0);
      const loading = ref(false);
      const options = ref({ page: 1, itemsPerPage: 10 });

      const token = useToken();
      const config = new Configuration({apiKey: token as string});
      const statsApi = StatsApiFactory(config);
      const page = ref(1);
      const pageSize = ref(7);

      const userHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'First Name', key: 'first_name' },
        { title: 'Last Name', key: 'last_name' },
        { title: 'Email', key: 'email' },
        { title: 'Phone', key: 'phone' },
        { title: 'Created At', key: 'created_at' },
      ];

      const userTopChattersHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'First Name', key: 'first_name' },
        { title: 'Last Name', key: 'last_name' },
        { title: 'Email', key: 'email' },
        { title: 'Phone', key: 'phone' },
        { title: 'Created At', key: 'created_at' },
        { title: 'Total Messages', key: 'total_messages' },
      ];
      
      async function getUsersWithFullProfile(searchValue: string) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsUsersWithFullProfileGet(searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            users.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch users:', error);
        }
      }

      async function getUsersWithoutProfile(searchValue: string) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsUsersWithoutProfileGet(searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            users.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch UsersWithoutProfile:', error);
        }
      }

      async function getUsersWithoutTestResult(searchValue: string) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsUsersWithoutTestResultGet(searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            users.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch UsersWithoutTestResult:', error);
        }
      }

      async function getActiveUsers(searchValue: string, duration: number, unit: StatsActiveUsersGetUnitEnum) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsActiveUsersGet(duration, unit, searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            users.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch ActiveUsers:', error);
        }
      }
    
      async function getInactiveUsers(searchValue: string, duration: number, unit: StatsActiveUsersGetUnitEnum) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsInactiveUsersGet(duration, unit, searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            users.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch InactiveUsers:', error);
        }
      }

      async function getTopChatters(searchValue: string) {
        loading.value = true;
        if (searchValue !== '') {
          page.value = 1;
        }
        try {
          const response = await statsApi.statsTopChattersGet(searchValue, page.value, pageSize.value)
          if (response.data.data != null) {
            topChatters.value = response.data.data;
            total.value = response.data.total as number;
          }
          loading.value = false;
        } catch (error) {
          loading.value = false;
          console.error('Failed to fetch TopChatters:', error);
        }
      }

      watch(search, (newSearchValue) => {
        switch (props.endpoint) {
          case 'with-full-profile':
            getUsersWithFullProfile(newSearchValue);
            break;
          case 'without-profile':
            getUsersWithoutProfile(newSearchValue);
            break;
          case 'without-tests':
            getUsersWithoutTestResult(newSearchValue);
            break;
          case 'active-users':
            getActiveUsers(newSearchValue, props.duration, props.unit as StatsActiveUsersGetUnitEnum);
            break;
          case 'inactive-users':
            getInactiveUsers(newSearchValue, props.duration, props.unit as StatsActiveUsersGetUnitEnum);
            break;
          case 'top-chatters':
            getTopChatters(newSearchValue);
            break;
          default:
            break;
        }
      });

      watch([page, pageSize, options], () => {
        switch (props.endpoint) {
          case 'with-full-profile':
            getUsersWithFullProfile(search.value);
            break;
          case 'without-profile':
            getUsersWithoutProfile(search.value);
            break;
          case 'without-tests':
            getUsersWithoutTestResult(search.value);
            break;
          case 'active-users':
            getActiveUsers(search.value, props.duration, props.unit as StatsActiveUsersGetUnitEnum);
            break;
          case 'inactive-users':
            getInactiveUsers(search.value, props.duration, props.unit as StatsActiveUsersGetUnitEnum);
            break;
          case 'top-chatters':
            getTopChatters(search.value);
            break;
          default:
            break;
        }
      });

  
      return {
        page,
        pageSize,
        search,
        userHeaders,
        userTopChattersHeaders,
        topChatters,
        users,
        total,
        loading,
        options,
        onOptionsChanged: users,
      };
    },
  };

  