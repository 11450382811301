import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/HomeView.vue';
import UserList from './components/UserList.vue';
import EntityList  from './components/EntityList.vue';
import QuestionList from './components/QestionList.vue'
import AnswerList from './components/AnswerList.vue'
import LoginComponent from './components/LoginComponent.vue'
import RegisterComponent from './components/RegisterComponent.vue'
import { useToken } from './auth';
import DashboardComponent from './components/DashboardComponent.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'DashboardComponent',
    component: DashboardComponent,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: UserList,
    meta: { requiresAuth: true }
  },
  {
    path: '/entities',
    name: 'Entities',
    component: EntityList,
    meta: { requiresAuth: true }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: QuestionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/answers',
    name: 'Answers',
    component: AnswerList,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const token = useToken();
  if (to.meta.requiresAuth && !token) {
    next('/login');
  } else {
    next();
  }
});

export default router;
