

import { onMounted, ref, computed, reactive } from 'vue';
import AnswerTmpl from '@/components/AnswerTmpl.vue';
import { Answer, Answers } from '@/types';
import { AxiosError } from 'axios';
import { 
    Configuration, 
    UsersApiFactory, 
    DefsUserData,
} from '../../api';
import { useToken } from '../auth';

export default {
    components: {
        AnswerTmpl
    },
    setup() {
        const token = useToken();
        const config = new Configuration({ apiKey: token as string });
        const usersApi = UsersApiFactory(config);
        const users = ref<DefsUserData[]>([]);
        const selectedUser = ref<DefsUserData>();
        
        let isDataLoaded = ref(false);
        let isLoading = ref(false);

        const notification = reactive({
            show: false,
            message: '',
            color: 'success'
        });

        const answers: Answers = reactive({
            user_id: "",
            mbti: [] as Answer[],
            attachment: [] as Answer[],
        });


        function cleenAnswers(testAnswer: Answers): Answers {
            return {
                ...testAnswer,
                mbti: testAnswer.mbti?.map(ans => ({  
                    question_id: ans.question_id,
                    score: ans.score
                })),
                attachment: testAnswer.attachment?.map(ans => ({  
                    question_id: ans.question_id,
                    score: ans.score
                })),
            };
        }

        const handleAnswers = (qType: string, newAnswers: Answers) => {
            answers[qType] = newAnswers;
        };

        const displayUsers = computed(() => {
            return users.value.map(user => ({
                ...user,
                fullName: `${user.first_name} ${user.last_name} (${user.email})`
            }));
        });


        const fetchUsers = debounce(async (searchValue: string) => {
            isLoading.value = true;
            try {
                const response = await usersApi.usersGet(searchValue);
                if (response.data.data != null) {
                    users.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
            isLoading.value = false;
        }, 200);

        const hideNotification = () => {
            setTimeout(() => {
                notification.show = false;
            }, 3000);
        };

        async function saveAnswers() {
            const req = cleenAnswers(answers)
            if (req.attachment?.length === 0 || req.mbti?.length === 0) {
                notification.color = 'error';
                notification.message = 'You need to answer to the attachment and mbti questions';
                notification.show = true;
                hideNotification()
                return
            }
            const userId = selectedUser.value?.id
            req.user_id = userId as string
            try {
                await usersApi.usersUserIDAnswersPost(req.user_id as string, req)
                notification.message = 'Answers saved successfully!';
                notification.color = 'success';
            } catch (error) {
                notification.color = 'error';
                if(error instanceof AxiosError) {
                    notification.message = error.response?.data.message
                } else {
                    notification.message = 'An unexpected error occurred';
                }
            }
            notification.show = true;
            hideNotification()
        }

        const userExists = (userId: string | undefined) => {
            return users.value.some(user => user.id === userId);
        };

        const clearUsers = () => {
            users.value = [{
                email: "",
                phone: ""
            }];
        };

        onMounted(initialize);

        function debounce(func: { (searchValue: string): Promise<void>; apply?: any; }, delay: number | undefined) {
            let timeoutId: number | undefined;
            return (...args: any) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func.apply(null, args);
                }, delay);
            };
        }

        function initialize() {
            console.log("initialize")
        }

        return {
            notification,
            fetchUsers,
            initialize,
            AnswerTmpl,
            saveAnswers,
            users,
            selectedUser,
            isDataLoaded,
            isLoading,
            userExists,
            clearUsers,
            displayUsers,
            answers,
            handleAnswers,
        }
    }
}

