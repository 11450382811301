import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_image_upload = _resolveComponent("image-upload")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.localDialog,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localDialog) = $event)),
    "max-width": "600px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "#aa6010" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("User Profile")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
            color: "#aa6010"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: "personal" }, {
                default: _withCtx(() => [
                  _createTextVNode("Personal Details")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "additional" }, {
                default: _withCtx(() => [
                  _createTextVNode("Additional Information")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "image" }, {
                default: _withCtx(() => [
                  _createTextVNode("Images")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_window, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window_item, { value: "personal" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "card" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, {
                            class: "scrollbar-on-hover",
                            style: {"max-height":"60vh","overflow-y":"auto"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProfileData, (value, key) => {
                                return (_openBlock(), _createBlock(_component_v_row, { key: key }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(key), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_col, { cols: "6" }, {
                                      default: _withCtx(() => [
                                        (typeof value === 'boolean')
                                          ? (_openBlock(), _createBlock(_component_v_switch, {
                                              key: 0,
                                              modelValue: _ctx.profile.data[key],
                                              "onUpdate:modelValue": ($event: any) => ((_ctx.profile.data[key]) = $event),
                                              inset: ""
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                          : (typeof value === 'number')
                                            ? (_openBlock(), _createBlock(_component_v_text_field, {
                                                key: 1,
                                                modelValue: _ctx.profile.data[key],
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.profile.data[key]) = $event),
                                                modelModifiers: { number: true },
                                                type: "number",
                                                variant: "solo"
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                            : (typeof value === 'string')
                                              ? (_openBlock(), _createBlock(_component_v_textarea, {
                                                  key: 2,
                                                  modelValue: _ctx.profile.data[key],
                                                  "onUpdate:modelValue": ($event: any) => ((_ctx.profile.data[key]) = $event),
                                                  type: "text",
                                                  variant: "solo",
                                                  "auto-grow": "",
                                                  rows: "1"
                                                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                              : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "additional" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "card" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, {
                            class: "scrollbar-on-hover",
                            style: {"max-height":"60vh","overflow-y":"auto"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Education ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.educations,
                                        "item-title": "education",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedEducation,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedEducation) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Eye Colors ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.eyeColors,
                                        "item-title": "color",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedEyeColor,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedEyeColor) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Body Types ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.bodyTypes,
                                        "item-title": "type",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedBodyType,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedBodyType) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Profession ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.professions,
                                        "item-title": "job_title",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedProfession,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedProfession) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Genders ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.genders,
                                        "item-title": "gender",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedGender,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedGender) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Sexual Orientation ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.sexOrientations,
                                        "item-title": "orientation",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedSexOrientation,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedSexOrientation) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Interest ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.interests,
                                        "item-title": "interest",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedInterests,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedInterests) = $event)),
                                        variant: "solo",
                                        multiple: ""
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Hobby ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.hobbies,
                                        "item-title": "hobby",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedHobbies,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedHobbies) = $event)),
                                        variant: "solo",
                                        multiple: ""
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Race ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        items: _ctx.races,
                                        "item-title": "ethnicity",
                                        "item-value": "id",
                                        modelValue: _ctx.selectedRaces,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedRaces) = $event)),
                                        variant: "solo",
                                        multiple: ""
                                      }, null, 8, ["items", "modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "image" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_image_upload, {
                        userId: _ctx.userId,
                        editmMode: _ctx.editmMode,
                        imageList: _ctx.profileImageList
                      }, null, 8, ["userId", "editmMode", "imageList"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "orange darken-1",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                color: "orange darken-1",
                onClick: _ctx.saveProfile
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}