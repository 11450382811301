
import ImageUpload from './ImageUpload.vue';
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { formatKey } from '@/helpers';
import {
  Configuration, UsersApiFactory, DefsUserProfileReq, EntitiesApiFactory,
  DefsEducationData, DefsBodyTypeData, DefsEyesColorData, DefsGenderData, DefsSexualOrientationData, DefsProfessionData,
  DefsInterestsData, DefsHobbyData, DefsRaceData, DefsUserProfileResponse, DefsPicturesRes, ModelsImageInfo
} from '../../api';
import { useToken } from '../auth';
import { mapObjectsToIds, mapIdsToObjects } from '../helpers';
import preImage from '@/assets/pre.webp';

export default defineComponent({
  name: 'UserProfile',
  props: {
    dialog: Boolean,
    userID: {
      type: String,
      default: "",
    },
  },
  components: {
    ImageUpload
  },
  setup(props, { emit }) {
    const token = useToken();
    const config = new Configuration({ apiKey: token as string });
    const usersApi = UsersApiFactory(config);
    const entitiesApi = EntitiesApiFactory(config)
    const profile = ref<DefsUserProfileResponse>({
      data: {
        about_me: "",
        nickname: "",
        metric: "cm",
        birth_date: "1980-01-01T00:00:00Z",
        height: 180,
        income_max: 100000,
        income_min: 10,
        smoking: false,
        drinking: false,
        have_children: false,
        want_children: true,
        like_pets: true,
        have_pets: false,
      }
    });
    const localDialog = ref(props.dialog);
    const tab = ref('basic');
    const userId = ref<string>("")
    const editmMode = ref<boolean>(false)
    const profileImageList = ref<DefsPicturesRes[]>([])

    let educations = ref<DefsEducationData[]>();
    let selectedEducation = ref<string | undefined>("");

    let bodyTypes = ref<DefsBodyTypeData[]>();
    let selectedBodyType = ref<string | undefined>("");

    let eyeColors = ref<DefsEyesColorData[]>();
    let selectedEyeColor = ref<string | undefined>("");

    let genders = ref<DefsGenderData[]>();
    let selectedGender = ref<string | undefined>("");

    let sexOrientations = ref<DefsSexualOrientationData[]>();
    let selectedSexOrientation = ref<string | undefined>("");

    let professions = ref<DefsProfessionData[]>();
    let selectedProfession = ref<string | undefined>("");

    let interests = ref<DefsInterestsData[]>();
    let selectedInterests = ref<string[]>([]);

    let hobbies = ref<DefsHobbyData[]>();
    let selectedHobbies = ref<string[]>([]);

    let races = ref<DefsRaceData[]>();
    let selectedRaces = ref<string[]>([]);

    onMounted(async () => {
      console.log('user-profile-on-mounted, user-id: ' + props.userID)
    })

    watch(localDialog, (newVal, oldVal) => {
      if (!newVal && oldVal) {
        emit('update:dialog', false);
      }
    });

    watch(() => props.dialog, (newVal) => {
      localDialog.value = newVal;
      //loadProfile(props.userID)
    });

    const close = () => {
      emit('update:dialog', false);
    };

    watch(() => props.userID, (newVal) => {
      userId.value = newVal
      loadProfile(props.userID)
    });


    async function getEntities(defaultValues: boolean) {
      try {
        const response = await entitiesApi.entitiesGet()
        if (response.data != null) {
          bodyTypes.value = response.data.data?.body_types
          educations.value = response.data.data?.educations
          eyeColors.value = response.data.data?.eye_colors
          genders.value = response.data.data?.genders
          hobbies.value = response.data.data?.hobbies
          interests.value = response.data.data?.interests
          professions.value = response.data.data?.professions
          races.value = response.data.data?.races
          sexOrientations.value = response.data.data?.sexual_orientations

          if (defaultValues) {
            if (selectedRaces.value && races.value) {
              selectedRaces.value[0] = races.value[0].id as string
            }
            if (selectedHobbies.value && hobbies.value) {
              selectedHobbies.value[0] = hobbies.value[0].id as string
            }
            if (selectedInterests.value && interests.value) {
              selectedInterests.value[0] = interests.value[0].id as string
            }
            if (selectedProfession.value && professions.value) {
              selectedProfession.value = professions.value[0].id as string
            }
            if (selectedSexOrientation.value && sexOrientations.value) {
              selectedSexOrientation.value = sexOrientations.value[0].id as string
            }
            if (selectedGender.value && genders.value) {
              selectedGender.value = genders.value[0].id as string
            }
            if (selectedEyeColor.value && eyeColors.value) {
              selectedEyeColor.value = eyeColors.value[0].id as string
            }
            if (selectedBodyType.value && bodyTypes.value) {
              selectedBodyType.value = bodyTypes.value[0].id as string
            }
            if (selectedEducation.value && educations.value) {
              selectedEducation.value = educations.value[0].id as string
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch chat-list:', error);
      }
    }

    const openDialog = () => {
      localDialog.value = true;
    };

    const closeDialog = () => {
      localDialog.value = false;
    };

    async function loadProfile(userID: string) {
      console.log("loadProfile")
      //profileId.value = ""
      await getEntities(false)
      if (userID == "" || userID == undefined) {
        return
      }
      try {
        const resp = await usersApi.usersUserIDProfilesGet(userID);
        if (resp.data["code"] == 200 && resp.data["message"] == 'sql: no rows in result set') {
          return
        }
        profile.value.data = resp.data.data
        let co = profile.value.data
        selectedEducation.value = co?.education?.id
        selectedGender.value = co?.gender?.id;
        selectedEyeColor.value = co?.eye_color?.id;
        selectedBodyType.value = co?.body_type?.id;
        selectedProfession.value = co?.profession?.id;
        selectedSexOrientation.value = co?.sexual_orientation?.id;
        selectedInterests.value = mapObjectsToIds(co?.interests);
        selectedHobbies.value = mapObjectsToIds(co?.hobbies);
        selectedRaces.value = mapObjectsToIds(co?.races);

        if (co?.pictures?.length as number > 0) {
          editmMode.value = true
          console.log(co?.pictures)
          profileImageList.value = []
          co?.pictures?.forEach(pic => {
            if (pic.info?.name?.includes("original")) {
              profileImageList.value.push(pic)
            }
          });
        } else {
          editmMode.value = false
          let def = {info:{signed_url:preImage}}
          profileImageList.value = [def, def, def, def]
        }
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    }

    async function saveProfile() {
      try {
        let pr = profile.value.data;
        let req = ref<DefsUserProfileReq>({
          user_id: pr?.user_id as string,
          birth_date: pr?.birth_date as string,
          about_me: pr?.about_me,
          drinking: pr?.drinking,
          have_children: pr?.have_children,
          have_pets: pr?.have_pets,
          height: pr?.height,
          income: {
            min: pr?.income_min,
            max: pr?.income_max,
          },
          want_children: pr?.want_children,
          like_pets: pr?.like_pets,
          metric: pr?.metric,
          nickname: pr?.nickname,
          smoking: pr?.smoking,
          body_type_id: selectedBodyType.value,
          education_id: selectedEducation.value,
          eye_color_id: selectedEyeColor.value,
          gender_id: selectedGender.value,
          profession_id: selectedProfession.value,
          sexual_orientation_id: selectedSexOrientation.value,
          races: selectedRaces.value,
          hobbies: selectedHobbies.value,
          interests: selectedInterests.value,
        });
        console.log(req.value)
        const response = await usersApi.usersUserIDProfilesPost(props.userID, req.value as DefsUserProfileReq, "")
        console.log(response)
        closeDialog()
      } catch (error) {
        console.error('Failed to save-пrofile:', error);
      }
    }

    // const dialogTitle = computed(() => {
    //   if (props.mode === 'edit') return `Edit Profile: ${props.userID}`;
    //   if (props.mode === 'create') return 'Create New Profile';
    //   return props.userID;
    // });

    const shouldDisplay = (key: string) => {
      const keysToExclude = ["id", "user_id", "education", "gender", "eye_color",
        "body_type", "profession", "sexual_orientation",
        "interests", "hobbies", "races", "pictures"];
      return !keysToExclude.includes(key);
    };

    const filteredProfileData = computed(() => {
      const data = profile.value.data;
      const filteredData = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && shouldDisplay(key)) {
          filteredData[key] = data[key];
        }
      }
      return filteredData;
    });

    return {
      editmMode,
      profileImageList,
      userId,
      filteredProfileData,
      shouldDisplay,
      localDialog,
      openDialog,
      closeDialog,
      formatKey,
      profile,
      saveProfile,
      // dialogTitle,
      getEntities,
      educations,
      bodyTypes,
      eyeColors,
      hobbies,
      interests,
      races,
      genders,
      sexOrientations,
      professions,
      selectedGender,
      selectedRaces,
      selectedEyeColor,
      selectedBodyType,
      selectedProfession,
      selectedSexOrientation,
      selectedInterests,
      selectedHobbies,
      selectedEducation,
      tab,
    };
  },
});
