import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48a4e40e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "user-list" }
const _hoisted_3 = { class: "chat" }
const _hoisted_4 = { class: "message-container" }
const _hoisted_5 = { class: "send-zone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $setup.localChatDialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.localChatDialogVisible) = $event)),
    width: "inherit"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, { color: "#aa6010" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "",
            dark: "",
            onClick: $setup.closeDialog
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-close")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.selectedUser?.first_name || 'Select a user'), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newChatUser) = $event)),
              type: "text"
            }, null, 512), [
              [_vModelText, $setup.newChatUser]
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_list, { dense: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chatUsers?.data, (user, index) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: (user.match_user?.id),
                  class: _normalizeClass({ 'active-user': $setup.selectedUserIndex === index }),
                  onClick: ($event: any) => ($setup.selectUser(user.match_user, index))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(user.match_user?.first_name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createElementBlock("div", {
            ref: "messagesContainer",
            class: "messages",
            id: "messages",
            key: $setup.selectedUser?.id
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.messages, (m, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'm-' + idx,
                  style: {"clear":"both"}
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass({ 'msg-from-me': m.is_sent_by_user == true, 'msg-from-other': m.is_sent_by_user == false })
                  }, _toDisplayString(m.content), 3)
                ]))
              }), 128))
            ])
          ])),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newMessage) = $event)),
              type: "text",
              placeholder: "Type a message",
              onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($setup.sendMessage && $setup.sendMessage(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, $setup.newMessage]
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}