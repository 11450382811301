import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      placeholder: "Username"
    }, null, 512), [
      [_vModelText, _ctx.email]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "password",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
      placeholder: "Password"
    }, null, 512), [
      [_vModelText, _ctx.password]
    ]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)))
    }, "Register")
  ]))
}