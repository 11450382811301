import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_EntityTmpl = _resolveComponent("EntityTmpl")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createBlock(_component_v_expansion_panels, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Professions ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Profession,
                entity: $setup.ent.professions.value,
                headers: $setup.ent.professionHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Sexual Orientations ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.SexualOrientation,
                entity: $setup.ent.sexOrientations.value,
                headers: $setup.ent.sexlOrientationHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Genders ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Gender,
                entity: $setup.ent.genders.value,
                headers: $setup.ent.genderHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Races ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Race,
                entity: $setup.ent.races.value,
                headers: $setup.ent.raceHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Interests ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Interest,
                entity: $setup.ent.interests.value,
                headers: $setup.ent.interestHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Hobbies ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Hobby,
                entity: $setup.ent.hobbies.value,
                headers: $setup.ent.hobbyHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Eye Colors ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.EyeColor,
                entity: $setup.ent.eyeColors.value,
                headers: $setup.ent.eyeColorHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Body Types ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.BodyType,
                entity: $setup.ent.bodyTypes.value,
                headers: $setup.ent.bodyTypeHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "4",
                    class: "d-flex justify-start"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Educations ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_expansion_panel_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_EntityTmpl, {
                name: $setup.EntityType.Education,
                entity: $setup.ent.educations.value,
                headers: $setup.ent.educationHeaders,
                en: $setup.ent
              }, null, 8, ["name", "entity", "headers", "en"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}