

import { onMounted, PropType, ref, watch, computed } from 'vue';
import { Header, EntityType } from '@/types';
import { Entities } from '@/classes/entities';

export default {
    name: 'EntityTmpl',
    props: {
        entity: {
            type: Array as PropType<any[]>,
            required: true,
        },
        headers: {
            type: Array as PropType<Header[]>,
            required: true,
        },
        mode: {
            type: String as PropType<string>,
            required: false,
            default: 'view',
        },
        name: {
            type: String as PropType<EntityType>,
            required: true,
        },
        en: {
            type: Entities as PropType<Entities>,
            required: true,
        },
    },

    setup(props) {
        const dialog = ref(false);
        const editedIndex = ref(-1);
        const formTitle = computed(() => editedIndex.value === -1 ? 'New ' + props.name : 'Edit ' + props.name);
        let editedItem = ref<Record<string, any>>({});
        let defaultItem = ref<Record<string, any>>({});

        onMounted(initialize);

        watch(dialog, (val) => {
            if (!val) close();
        });

        function initialize() {
            console.log("initialize")
            newItem()
        }

        function newItem() {
            if (props.entity.length > 0) {
                let keys = Object.keys(props.entity[0]);
                let newItem: Record<string, any> = {};
                keys.forEach(key => {
                    newItem[key] = '';
                });
                if (Object.prototype.hasOwnProperty.call(newItem, 'id')) {
                    delete newItem['id'];
                }
                defaultItem.value = newItem;
            }
            editedItem.value = { ...defaultItem.value };
        }

        function editItem(item: any) {
            editedIndex.value = props.entity.indexOf(item);
            editedItem.value = Object.assign({}, item);
            dialog.value = true
        }

        function deleteItem(item: any) {
            editedIndex.value = props.entity.indexOf(item);
            editedItem.value = Object.assign({}, item);
            deleteEntity(item.id)
        }

        async function deleteEntity(id: string) {
            try {
                await props.en.delelte(props.name, id)
                await props.en.getEntity(props.name)
            } catch (error) {
                console.error(`An unexpected error occurred: ${error}`);
            }
        }

        async function saveEntity() {
            if (editedIndex.value > -1) {
                Object.assign(props.entity[editedIndex.value], editedItem.value);
                try {
                    await props.en.save(props.name, editedItem.value.id, editedItem.value)
                    await props.en.getEntity(props.name)
                } catch (error) {
                    console.error(`An unexpected error occurred: ${error}`);
                    throw error;
                }
            } else {
                try {
                    await props.en.save(props.name, "", editedItem.value);
                    await props.en.getEntity(props.name)
                } catch (error) {
                    console.error(`An unexpected error occurred: ${error}`);
                    throw error;
                }
            }
            close();
        }

        function close() {
            dialog.value = false;
            editedIndex.value = -1;
            newItem()
        }

        return {
            initialize,
            props,
            editItem,
            deleteItem,
            saveEntity,
            deleteEntity,
            close,
            dialog,
            editedItem,
            formTitle,
            newItem,
        }
    }
}

