
import { defineComponent, ref } from 'vue';
import { provideToken } from '../auth';
import { Configuration, AuthApiFactory, DefsLoginReq } from '../../api';
import { useRouter } from 'vue-router';
import { useAuth } from '../useAuth';

export default defineComponent({
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const config = new Configuration({});
    const authApi = AuthApiFactory(config);
    const { updateLoginState } = useAuth();

    async function login() {
      updateLoginState(true);
      const loginReq: DefsLoginReq = {
        email: email.value,
        password: password.value
      };
      try {
        const response = await authApi.authLoginPost(loginReq);
        provideToken(response.data.data?.token as string);
        localStorage.setItem('authToken', response.data.data?.token as string);
        router.push('/');
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
    return {
      email,
      password,
      login
    };
  }
});
