
// import { Configuration, UsersApiFactory, DefsUserData, DefsUserProfileReq, DefsUserProfileRes, DefsUserPreferencesData, DefsUserSettingsData } from '../../api';

import { defineComponent, ref, watch, PropType, onMounted } from 'vue';
// import { URL, LanguageType, UserSettings, ThemeType, EResponse } from '@/types';
// import { URL} from '@/types';
// import { AxiosError } from 'axios';
// import { save, getData } from '@/helpers';
// import { notify } from "@kyvg/vue3-notification";


import { 
  DefsUserSettingsData, ModelsTheme, Configuration, SettingsApiFactory, DefsUserSettingsResponse
  
} from '../../api';

import { useToken } from '../auth';

export default defineComponent({
  props: {
    dialog: Boolean,
    userID: {
      type: String,
      default: "",
    },
  },

  setup(props, { emit }) {
    const formTitle = 'Edit User Settings';
    const token = useToken();
    const config = new Configuration({ apiKey: token as string });
    const settingsApi = SettingsApiFactory(config);
    const settings = ref<DefsUserSettingsData>({
        id: "",
        user_id: props.userID,
        show_status: true,
        language: "",
        show_age: true,
        show_body_type: true,
        show_children: true,
        show_drinking: true,
        show_education: true,
        show_eye_color: true,
        show_income: true,
        show_location: true,
        show_pets: true,
        show_profession: true,
        show_sexual_orientation: true,
        show_smoking: true,
        show_zodiac_sign: true,
        theme: ModelsTheme.ThemeWhite,
    });


    let edit = false

    // const defaultSettings: UserSettings = {
    //   userId: props.settings?.userId == undefined ? "" : props.settings.userId,
    //   theme: ThemeType.Orange,
    //   language: LanguageType.English,
    //   showStatus: true,
    //   showLocation: true,
    //   showAge: true,
    //   showIncome: true,
    //   showHeight: true,
    //   showProfession: true,
    //   showEducation: true,
    //   showZodiacSign: true,
    //   showBodyType: true,
    //   showEyeColor: true,
    //   showSmoking: true,
    //   showDrinking: true,
    //   showChildren: true,
    //   showPets: true,
    //   showSexualOrientation: true
    // };

    const localDialog = ref(props.dialog);
    // const localSettings = ref({ ...defaultSettings, ...props.settings });
    const tab = ref('basic');

    watch(localDialog, (newVal, oldVal) => {
      if (!newVal && oldVal) {
        emit('update:dialog', false);
      }
    });

    watch(() => props.dialog, (newVal) => {
      localDialog.value = newVal;
      getSettings(props.userID)
    });

    // watch(() => props.settings, (newVal) => {
    //   // localSettings.value = { ...localSettings.value, ...newVal };
    // });

    // watch(() => props.settings, (newVal) => {
    //   // localSettings.value = { ...defaultSettings, ...newVal };
    // }, { deep: true });

    const closeDialog = () => {
      localDialog.value = false;
    };

    async function getSettings(userId: string) {
      try {
        edit = true
        console.log('getSettings, userId:'+userId)
        const resp = await settingsApi.usersUserIDSettingsGet(userId)
        if(resp.data["code"] == 200 && resp.data["message"] == 'sql: no rows in result set') {
          edit = false
          console.log('getSettings, new')
          return
        }
        let se = resp.data.data
        console.log(se)
        settings.value.id = se?.id as string
        settings.value.language = se?.language
        settings.value.show_age = se?.show_age
        settings.value.show_body_type = se?.show_body_type
        settings.value.show_children = se?.show_children
        settings.value.show_drinking = se?.show_drinking
        settings.value.show_education = se?.show_education
        settings.value.show_eye_color = se?.show_eye_color
        settings.value.show_height = se?.show_height
        settings.value.show_income = se?.show_income
        settings.value.show_location = se?.show_location
        settings.value.show_pets = se?.show_pets
        settings.value.show_profession = se?.show_profession
        settings.value.show_sexual_orientation = se?.show_sexual_orientation
      } catch (error) {
        console.error('failed to get-settings:', error)
      }
    }

    async function saveSettings (){
      console.log('saveSettings')
      emit('update:dialog', false);
      emit('save:settings', settings.value);
      console.log(settings.value)
      try {
        let st = settings.value;
        let req = ref<DefsUserSettingsData>({
          user_id: props.userID,
          show_status: st.show_status,
          language: st.language,
          show_age: st.show_age,
          show_height: st.show_height,
          show_body_type: st.show_body_type,
          show_children: st.show_children,
          show_drinking: st.show_drinking,
          show_education: st.show_education,
          show_eye_color: st.show_eye_color,
          show_income: st.show_income,
          show_location: st.show_location,
          show_pets: st.show_pets,
          show_profession: st.show_profession,
          show_sexual_orientation: st.show_sexual_orientation,
          show_smoking: st.show_smoking,
          show_zodiac_sign: st.show_zodiac_sign,
          theme: st.theme,
        });
        let resp: any
        if(edit){
          req.value.id = st.id
          console.log("saveSettings, edit")
          console.log(req.value)
          resp = await settingsApi.usersUserIDSettingsPut(props.userID, req.value as DefsUserSettingsData)
        } else {
          console.log("saveSettings, new")
          resp = await settingsApi.usersUserIDSettingsPost(props.userID, req.value as DefsUserSettingsData)
        }
        
        // notify({ type: "error", text: msg });
      } catch (error) {
        console.error('failed to save-settings:', error);
      }

      closeDialog();
    }

    onMounted(async () => {
      console.log('onMounted')
    })

    const close = () => {
      emit('update:dialog', false);
    };

    return {
      formTitle,
      close,
      localDialog,
      settings,
      saveSettings,
      tab,
      closeDialog,
    };
  },
});
