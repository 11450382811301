import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_QTmpl = _resolveComponent("QTmpl")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_expansion_panels, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_expansion_panel, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "4",
                      class: "d-flex justify-start"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" MBTI ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_QTmpl, {
                  qType: "mbti",
                  "onUpdate:questions": _cache[0] || (_cache[0] = ($event: any) => ($setup.updateQuestions($event, 'attachment')))
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_expansion_panel, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "4",
                      class: "d-flex justify-start"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Attachment ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_QTmpl, {
                  qType: "attachment",
                  "onUpdate:questions": _cache[1] || (_cache[1] = ($event: any) => ($setup.updateQuestions($event, 'mbti')))
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: $setup.notification.show,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.notification.show) = $event)),
      color: $setup.notification.color
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.notification.message), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "color"])
  ], 64))
}