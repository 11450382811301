import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

const isLoggedIn = ref(localStorage.getItem('authToken') !== null);

export function useAuth() {
  const router = useRouter();
  
  function updateLoginState(isLoggedInState: boolean) {
    isLoggedIn.value = isLoggedInState;
    if (!isLoggedInState) {
      localStorage.removeItem('authToken');
      router.push('/login');
    } else {
      router.push('/');
    }
  }

  const menuItemText = computed(() => isLoggedIn.value ? "Logout" : "Login");
  const menuItemIcon = computed(() => isLoggedIn.value ? "mdi-exit-to-app" : "mdi-login");

  return { isLoggedIn, menuItemText, menuItemIcon, updateLoginState };
}
