import { reactive } from 'vue';

export const eventBus = reactive({
  emit(event: string | number, data: any) {
    if (this[event]) {
      this[event](data);
    }
  },
  on(event: string | number, callback: any) {
    this[event] = callback;
  }
});