


import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { Header} from '@/types';
import UserProfile from "./UserProfile.vue"
import UserPreferencesDialog from './UserPreferencesDialog.vue';
import UserSettingsDialog from './UserSettingsDialog.vue';
import { Configuration, UsersApiFactory, DefsUserData} from '../../api';
import { useToken } from '../auth';
import ChatComponent from './ChatComponent.vue';

export default {
  components: {
    UserProfile,
    UserPreferencesDialog,
    UserSettingsDialog,
    ChatComponent,
  },
  setup() {

    interface Message {
      id: number;
      text: string;
      fromMe: boolean;
    }
    const headers: Header[] = [
      { title: 'ID', key: 'id' },
      { title: 'Name', key: 'first_name' },
      { title: 'Email', key: 'email' },
      { title: 'Phone', key: 'phone' },
      { title: 'MBTI', key: 'mbti' },
      { title: 'Attachment', key: 'attachment' },
      { title: 'Created', key: 'created_at' },
      { title: 'Actions', key: 'actions', sortable: false },
    ];

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const chatDialogVisible  = ref(false);
    const selectedUser = ref<DefsUserData>();
    const newMessageText = ref("");
    const token = useToken();
    const config = new Configuration({apiKey: token as string});
    const usersApi = UsersApiFactory(config);
    const users = ref<DefsUserData[]>([]);
    const editedIndex = ref(-1);
    const editedItem = ref<DefsUserData>({ first_name: '', last_name: '', email: '', phone: '' });
    const defaultItem: DefsUserData = { first_name: '', last_name: '', email: '', phone: '' };
    const search = ref('');
    const expanded = ref([]);
    const singleExpand = ref(false)
    const formTitle = computed(() => editedIndex.value === -1 ? 'New User' : 'Edit User');
    const selectedUserMessages = ref<Message[]>([])
    const currentMode = ref('view'); // 'view' | 'edit' | 'new-profile'
    const preferencesDialogVisible = ref(false);
    const settingsDialogVisible = ref(false);
    const userProfileDialogVisible = ref(false)
    const page = ref(1);
    const pageSize = ref(7);
    const totalUsers = ref(0);
    const options = ref({ sortBy: ['firstName'], sortDesc: [false] });
    const currentUserID = ref("");
    const loading = ref(false)

    watch(dialog, (val) => {
      if (!val) close();
    });


    // onMounted(initialize);

    const closeDelteDialog = () => {
      console.log("closeDelteDialog")
      dialogDelete.value = false;
    };


    function selectUser(user: DefsUserData) {
      console.log('selectUser');
    }

    function sendMessage() {
      console.log('selectUser');
    }

    async function fetchUsers(searchValue: string) {
      loading.value = true;
      if (searchValue !== '') {
        page.value = 1;
      }
      try {
        const response = await usersApi.usersGet(searchValue, page.value, pageSize.value);
        if (response.data.data != null) {
          users.value = response.data.data;
          totalUsers.value = response.data.total as number;
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.error('Failed to fetch users:', error);
      }
    }

    async function saveUser(user: DefsUserData) {
      try {
        let response: any
        if (user.id == undefined) {
          response = await usersApi.usersPost(user)
        } else {
          response = await usersApi.usersUserIDPut(user.id, user)
        }
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    }

    async function deleteUser(user: DefsUserData) {
      try {
        if (user.id != undefined) {
          let response = await usersApi.usersUserIDDelete(user.id)
          console.log(response.data);
        } 
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    }

    function initialize() {
      fetchUsers("");
    }


    function userSettings(item: DefsUserData) {
      const userId = item.id ? item.id : ""
      currentUserID.value = userId
      settingsDialogVisible.value = true;
    }

    async function userPreferences(item: DefsUserData) {
      const userId = item.id ? item.id : ""
      currentUserID.value = userId
      preferencesDialogVisible.value = true;
    }

    async function chatView(item: DefsUserData) {
      try {
        console.log(item)
        const userId = item.id ? item.id : ""
        currentUserID.value = userId
        chatDialogVisible.value = true;
        await nextTick();
      } catch (error) {
        console.error(error)
      }
    }

    async function profileView(item: DefsUserData) {
      try {
        const userId = item.id ? item.id : ""
        currentUserID.value = userId
        console.log("profileView " +currentUserID.value)
        currentMode.value = 'edit';
        userProfileDialogVisible.value = true;
        await nextTick();
      } catch (error) {
        console.error(error)
      }
    }

    function editItem(item: DefsUserData) {
      editedIndex.value = users.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    }

    function deleteItem(item: DefsUserData) {
      editedIndex.value = users.value.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialogDelete.value = true;
      console.log('deleteItem');
    }

    function deleteItemConfirm() {
      users.value.splice(editedIndex.value, 1);
      closeDelete();
    }

    function close() {
      dialog.value = false;
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    }

    async function closeDelete() {
      dialogDelete.value = false;
      await deleteUser(editedItem.value);
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    }

    async function save() {
      if (editedIndex.value > -1) {
        Object.assign(users.value[editedIndex.value], editedItem.value);
        await saveUser(editedItem.value);
      } else {
        let id = await saveUser(editedItem.value);
        console.log(id);
        fetchUsers("");
      }
      close();
    }

    watch(search, (newSearchValue) => {
      fetchUsers(newSearchValue);
    });

    watch([page, pageSize, options], () => {
      fetchUsers("");
    });

    return {
      chatView,
      chatDialogVisible,
      selectedUserMessages,
      newMessageText,
      sendMessage,
      selectedUser,
      selectUser,
      initialize,
      dialog,
      dialogDelete,
      headers,
      users,
      editedIndex,
      editedItem,
      formTitle,
      editItem,
      deleteItem,
      deleteItemConfirm,
      close,
      closeDelete,
      save,
      search,
      userSettings,
      userPreferences,
      profileView,
      expanded,
      singleExpand,
      currentMode,
      userProfileDialogVisible,
      preferencesDialogVisible,
      settingsDialogVisible,
      closeDelteDialog,
      currentUserID,
      page, pageSize, totalUsers, options, loading
    }
  }
}

