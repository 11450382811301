import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Load vue core
import { createApp} from 'vue';

import App from './App.vue';

import router from '@/router';

import vuetify from './plugins/vuetify'

import 'vuetify/styles'

// Load Layout vue.
import '@/assets/main.css';

import Notifications from '@kyvg/vue3-notification'

/** Register Vue */
const vue = createApp(App);
vue.use(router);
vue.use(vuetify);
vue.use(Notifications)

// provide('vuetify', vuetify)

router
  .isReady()
  .then(() => {
    vue.mount('#app');
  })
  .catch(e => console.error(e));
  