
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    // function logout() {
    //   localStorage.removeItem('authToken');
    //   router.push('/login');
    // }

    return {
      // logout,
    };
  },
}

