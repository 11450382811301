import { ref, Ref } from 'vue';

const token: Ref<string | null> = ref(null);

// export function provideToken(newToken: string): void {
//     token.value = newToken;
// }

// export function useToken(): Ref<string | null> {
//     return token;
// }

export function provideToken(newToken: string): void {
    localStorage.setItem('authToken', newToken);
}

export function useToken(): string | null {
    return localStorage.getItem('authToken');
}