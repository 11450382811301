import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.localDialog,
    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localDialog) = $event)),
    "max-width": "600px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "#aa6010" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("User Settings")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
            color: "#aa6010"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: "personal" }, {
                default: _withCtx(() => [
                  _createTextVNode("Personal")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: "system" }, {
                default: _withCtx(() => [
                  _createTextVNode("System")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_window, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.tab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_window_item, { value: "personal" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "card" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Personal")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_switch, {
                                        label: "show status",
                                        modelValue: _ctx.settings.show_status,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.show_status) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show income",
                                        modelValue: _ctx.settings.show_income,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.show_income) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show zodiac sign",
                                        modelValue: _ctx.settings.show_zodiac_sign,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settings.show_zodiac_sign) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show smoking",
                                        modelValue: _ctx.settings.show_smoking,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settings.show_smoking) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show pets",
                                        modelValue: _ctx.settings.show_pets,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.settings.show_pets) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_switch, {
                                        label: "show location",
                                        modelValue: _ctx.settings.show_location,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.settings.show_location) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show height",
                                        modelValue: _ctx.settings.show_height,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.settings.show_height) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show body type",
                                        modelValue: _ctx.settings.show_body_type,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.settings.show_body_type) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show drinking",
                                        modelValue: _ctx.settings.show_drinking,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.settings.show_drinking) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show sexual orientation",
                                        modelValue: _ctx.settings.show_sexual_orientation,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.settings.show_sexual_orientation) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_switch, {
                                        label: "show age",
                                        modelValue: _ctx.settings.show_age,
                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.settings.show_age) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show profession",
                                        modelValue: _ctx.settings.show_profession,
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.settings.show_profession) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show eye color",
                                        modelValue: _ctx.settings.show_eye_color,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.settings.show_eye_color) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        label: "show children",
                                        modelValue: _ctx.settings.show_children,
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.settings.show_children) = $event)),
                                        variant: "solo"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_window_item, { value: "system" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "card" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("System")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Theme")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_radio_group, {
                                        modelValue: _ctx.settings.theme,
                                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.settings.theme) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_radio, {
                                            label: "Green",
                                            value: "green"
                                          }),
                                          _createVNode(_component_v_radio, {
                                            label: "Orange",
                                            value: "orange"
                                          }),
                                          _createVNode(_component_v_radio, {
                                            label: "Equal",
                                            value: "equal"
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Language")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_radio_group, {
                                        modelValue: _ctx.settings.language,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.settings.language) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_radio, {
                                            label: "English",
                                            value: "english"
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "orange-darken-2",
                onClick: _ctx.saveSettings
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}