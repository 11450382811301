import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_AnswerTmpl = _resolveComponent("AnswerTmpl")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_combobox, {
      onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => ($setup.fetchUsers($setup.selectedUser)), ["enter"])),
      onKeydown: _withKeys($setup.clearUsers, ["backspace"]),
      items: $setup.displayUsers,
      label: "search user...",
      "item-title": "fullName",
      "item-value": "id",
      variant: "solo",
      modelValue: $setup.selectedUser,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedUser) = $event))
    }, null, 8, ["onKeydown", "items", "modelValue"]),
    ($setup.selectedUser && $setup.userExists($setup.selectedUser.id))
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          class: "d-flex justify-start"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" MBTI ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_AnswerTmpl, {
                      qType: "mbti",
                      userId: $setup.selectedUser.id,
                      onUpdateAnswers: _cache[2] || (_cache[2] = answers => $setup.handleAnswers('mbti', answers))
                    }, null, 8, ["userId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "4",
                          class: "d-flex justify-start"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Attachment ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_AnswerTmpl, {
                      qType: "attachment",
                      userId: $setup.selectedUser.id,
                      onUpdateAnswers: _cache[3] || (_cache[3] = answers => $setup.handleAnswers('attachment', answers))
                    }, null, 8, ["userId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.selectedUser && $setup.userExists($setup.selectedUser.id))
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "blue darken-1",
              onClick: $setup.saveAnswers
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 2,
          indeterminate: "",
          color: "primary",
          size: 40,
          width: 4
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_snackbar, {
      modelValue: $setup.notification.show,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.notification.show) = $event)),
      color: $setup.notification.color
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.notification.message), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "color"])
  ], 64))
}