
import { defineComponent, PropType, watch, ref } from 'vue';
import { Configuration, ImagesApiFactory, DefsPicturesRes } from '../../api';
import { useToken } from '../auth';
import { BASE_PATH } from '../../api/base';

export default defineComponent({
    props: {
        userId: String,
        imageList: {
            type: Array as PropType<DefsPicturesRes[]>,
            required: true,
        },
        editmMode: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const images = ref<File[]>([]);
        const localImageList = ref();
        localImageList.value = props.imageList.map((image) => ({
            ...image,
            file: null,
            isNew: false,
        })).sort((a, b) => new Date(a.created_at as string).getTime() - new Date(b.created_at as string).getTime());

        const primaryIndex = ref<number | null>(0);
        const uploaded = ref<boolean>(false);
        const token = useToken();
        const config = new Configuration({ apiKey: token as string });
        const imagesApi = ImagesApiFactory(config);
        const isLoading = ref(false);

        watch(() => props.imageList, (newVal) => {
            localImageList.value = newVal.sort((a, b) => new Date(a.created_at as string).getTime() - new Date(b.created_at as string).getTime());
        });


        let formatDate = (date: Date) => {
            return new Date(date).toLocaleString();

        }

        const deleteImage = async (index: number, ppId: string) => {
            console.log("index " + index + " ppId " + ppId);
            try {
                let res = await imagesApi.imageUploadUserIDProfilePicturesProfilePicIDDupDelete(props.userId as string, ppId);
                if (res.status !== 200) {
                    throw new Error('Delete failed: ' + res.statusText);
                }
                localImageList.value = localImageList.value.filter((img) => img.id !== ppId);
            } catch (error) {
                console.error('Error deleting image:', error);
            }
        };


        const handleFileUpload = (index: number, img: DefsPicturesRes) => (event: Event) => {
            const files = (event.target as HTMLInputElement).files;
            if (files && files[0]) {
                resizeImage(files[0], 1024, 1024, (resizedBlob) => {
                    addImage(resizedBlob, index, img.id);
                });
            }
        };

        const addImage = (blob: Blob, index: number, picId?: string) => {
            const previewUrl = URL.createObjectURL(blob);
            const updatedImage = {
                ...localImageList.value[index],
                info: { ...(localImageList.value[index].info || {}), signed_url: previewUrl },
                file: blob,
                id: picId || localImageList.value[index].id,
                isNew: true
            };
            localImageList.value[index] = updatedImage;
        };

        const uploadImage = async (img: { file: string | Blob; isNew: any; }, url: string | URL) => {
            const formData = new FormData();
            formData.append('image_input', img.file);
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'x-auth': token as string
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Upload failed: ' + response.statusText);
            }
            return response.json();
        };

        const uploadNew = async () => {
            isLoading.value = true;
            try {
                console.log('uploadNew ' + props.editmMode)
                if (!props.editmMode) {
                    const formData = new FormData();

                    localImageList.value.forEach((img: { file: string | Blob; }, index: any) => {
                        if (img.file) {
                            formData.append(`image_input`, img.file);
                        }
                    });
                    // const postUrl = `http://127.0.0.1:7020/api/v1/image-upload/${props.userId}/profile-pictures`;
                    const postUrl = BASE_PATH + `/image-upload/${props.userId}/profile-pictures`;

                    const postResponse = await fetch(postUrl, {
                        method: 'POST',
                        headers: {
                            'x-auth': token as string
                        },
                        body: formData
                    });

                    if (!postResponse.ok) {
                        throw new Error('Upload failed: ' + postResponse.statusText);
                    }

                    const responseData = await postResponse.json();
                    console.log(responseData);
                } else {
                    for (const img of localImageList.value) {
                        if (img.isNew || img.file) {
                            //const putUrl = `http://127.0.0.1:7020/api/v1/image-upload/${props.userId}/profile-pictures/${img.id}`;
                            const putUrl = BASE_PATH + `/image-upload/${props.userId}/profile-pictures/${img.id}`;
                            await uploadImage(img, putUrl);
                        }
                    }
                }
            } catch (error) {
                console.error('Error during file upload:', error);
            } finally {
                isLoading.value = false;
            }
        };

        const setAsPrimary = (index: number) => {
            primaryIndex.value = index;
        };

        function resizeImage(file: Blob, maxWidth: number, maxHeight: number, callback: (arg0: Blob) => void) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result;
                if (typeof result === 'string') {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            callback(blob as Blob);
                        }, 'image/jpeg', 0.95); // Adjust the quality as needed
                    };
                    img.src = result;
                } else {
                    console.error('The result of file reading is not a string.');
                }
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
            reader.readAsDataURL(file);
        }

        return { localImageList, isLoading, handleFileUpload, uploadNew, setAsPrimary, uploaded, images, primaryIndex, deleteImage, formatDate };
    }
});
