import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_navigation_drawer, {
        modelValue: $setup.data().drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.data().drawer) = $event)),
        app: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, { onClick: $setup.toggleLoginState }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.menuItemIcon), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.menuItemText), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_list_item, { to: "/dashboard" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-home")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Dashboard")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { to: "/users" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-account")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Users")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { to: "/entities" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-layers")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Entities")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { to: "/questions" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-help-circle")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Questions")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { to: "/answers" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_action, { class: "d-flex align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-comment-check-outline")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item_title, { class: "ml-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Answers")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_app_bar, {
        app: "",
        "clipped-left": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.data().drawer = !$setup.data().drawer), ["stop"]))
          }),
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Equal Admin")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_notifications, { position: "bottom center" })
    ]),
    _: 1
  }))
}