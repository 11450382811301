import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60ce2404"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-upload-wrapper" }
const _hoisted_2 = { class: "image-upload-container" }
const _hoisted_3 = ["id", "onChange"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "image-preview-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "image-upload-placeholder"
}
const _hoisted_8 = { class: "image-overlay" }
const _hoisted_9 = {
  key: 0,
  class: "image-info"
}
const _hoisted_10 = { class: "image-date" }
const _hoisted_11 = { class: "image-date" }
const _hoisted_12 = { class: "image-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localImageList, (img, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['image-upload-box', { 'primary': index >= 0 }])
        }, [
          _createElementVNode("input", {
            type: "file",
            id: 'file-input-' + index,
            onChange: (event) => _ctx.handleFileUpload(index, img)(event),
            hidden: ""
          }, null, 40, _hoisted_3),
          _createTextVNode(),
          _createElementVNode("label", {
            for: 'file-input-' + index,
            class: "image-upload-label"
          }, [
            _createElementVNode("div", _hoisted_5, [
              (img && img.info && img.info.signed_url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: img.info.signed_url,
                    class: "image-preview"
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, "Upload Image")),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_v_btn, {
                  class: "delete-btn",
                  color: "error",
                  onClick: _withModifiers(($event: any) => (_ctx.deleteImage(index, img.id)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-delete")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                (img && img.created_at && img.updated_at)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(img.id), 1),
                      _createElementVNode("div", _hoisted_11, "Created: " + _toDisplayString(_ctx.formatDate(img.created_at)), 1),
                      _createElementVNode("div", _hoisted_12, "Updated: " + _toDisplayString(_ctx.formatDate(img.updated_at)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 8, _hoisted_4)
        ], 2))
      }), 128))
    ]),
    _createTextVNode(),
    _createVNode(_component_v_btn, {
      color: "orange darken-1",
      onClick: _ctx.uploadNew
    }, {
      default: _withCtx(() => [
        _createTextVNode("Upload")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createTextVNode(),
    _createVNode(_component_v_overlay, {
      "model-value": _ctx.isLoading,
      class: "align-center justify-center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_circular, {
          color: "primary",
          indeterminate: "",
          size: "64"
        })
      ]),
      _: 1
    }, 8, ["model-value"])
  ]))
}