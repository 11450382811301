
  import { defineComponent, PropType, ref } from 'vue';
  import { StatsActiveUsersGetUnitEnum} from '../../api';

  export default defineComponent({
    name: 'SquareButton',
    props: {
      title: {
        type: String,
        default: 'Active Users',
      },
      count: {
        type: Number as PropType<number>,
        required: true,
      },
      endpoint: {
        type: String,
        default: 'active-users',
      },
      withControls: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['refresh', 'click'],
    setup(props, { emit }) {

      const units = [StatsActiveUsersGetUnitEnum.Hours, StatsActiveUsersGetUnitEnum.Days, StatsActiveUsersGetUnitEnum.Minutes];
      const selectedUnit = ref(units[0]);
      const duration = ref(10);
      const showControls = ref(false);

      const toggleControls = () => {
        showControls.value = !showControls.value;
      };

      const fetchData = () => {
        emit('refresh', { endpoint:props.endpoint, unit: selectedUnit.value, duration: duration.value as number});
      };

      // const emitRefresh = () => {
      //   emit('refresh', { endpoint:props.endpoint, unit: selectedUnit.value, duration: duration.value });
      // };

      const onClick = () => {
        emit('click');
      };
  
      return { 
        onClick,
        units,
        selectedUnit,
        duration,
        showControls,
        toggleControls,
        fetchData,
        // emitRefresh,
      };
    },
  });
  