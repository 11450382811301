import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createBlock(_component_v_data_table, {
    headers: $setup.props.headers,
    items: $setup.props.entity,
    "item-key": "id",
    class: "text-start elevation-1"
  }, {
    top: _withCtx(() => [
      _createVNode(_component_v_toolbar, {
        color: "grey-darken-4",
        dark: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_dialog, {
            modelValue: $setup.dialog,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dialog) = $event)),
            "max-width": "500px"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps({
                color: "blue-darken-2",
                dark: "",
                class: "mb-"
              }, props), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-plus")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString($setup.formTitle), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.editedItem, (value, key, index) => {
                            return (_openBlock(), _createBlock(_component_v_row, {
                              dense: "",
                              key: index
                            }, {
                              default: _withCtx(() => [
                                (key !== 'id')
                                  ? (_openBlock(), _createBlock(_component_v_col, {
                                      key: 0,
                                      cols: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(key), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (key !== 'id')
                                  ? (_openBlock(), _createBlock(_component_v_text_field, {
                                      key: 1,
                                      modelValue: $setup.editedItem[key],
                                      "onUpdate:modelValue": ($event: any) => (($setup.editedItem[key]) = $event),
                                      label: key
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "blue-darken-2",
                        variant: "text",
                        onClick: $setup.close
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Cancel")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        color: "blue-darken-2",
                        variant: "text",
                        onClick: $setup.saveEntity
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Save")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    [`item.actions`]: _withCtx(({ item }) => [
      _createVNode(_component_v_icon, {
        color: "green-darken-2",
        size: "small",
        class: "me-3",
        onClick: ($event: any) => ($setup.editItem(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode("mdi-pencil ")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_v_icon, {
        color: "orange-darken-3",
        size: "small",
        class: "me-3",
        onClick: ($event: any) => ($setup.deleteItem(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" mdi-delete ")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 2
  }, 1032, ["headers", "items"]))
}