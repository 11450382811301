

import { ref, watch, onMounted, PropType } from 'vue';
import { Header } from '@/types';
import {
    Configuration,
    UsersApiFactory,
    QuestionsApiFactory,
    DefsTestAnswerData,
    DefsMBTIQuestion,
    DefsQuestionsData,
    DefsMBTIAnswerData
} from '../../api';
import { useToken } from '../auth';

export default {
    name: 'AnswerTmpl',
    props: {
        qType: {
            type: String as PropType<string>,
            required: true,
        },
        userId: {
            type: String as PropType<string>,
            required: false,
        },
    },
    setup(props, { emit }) {
        const dialog = ref(false);
        const dialogDelete = ref(false);
        let headers: Header[] = [
            { title: 'ID', key: 'id' },
            { title: 'Qustion', key: 'question' },
            { title: 'Score', key: 'score' },
            { title: 'Actions', key: 'actions', sortable: false },
        ];

        const questions = ref<DefsQuestionsData>();
        const answers = ref<DefsMBTIAnswerData[]>([]);
        const editedItem = ref<DefsMBTIAnswerData>({ id: "", question_id: "", score: 0 });
        const defaultItem: DefsMBTIAnswerData = { id: "", question_id: "", score: 0 };
        const editedIndex = ref(-1);
        const scores = [1, 2, 3, 4, 5]

        const token = useToken();
        const config = new Configuration({ apiKey: token as string });
        const qsApi = QuestionsApiFactory(config);
        const userApi = UsersApiFactory(config);

        watch(dialog, (val) => {
            if (!val) close();
        });

        watch(dialogDelete, (val) => {
            if (!val) closeDelete();
        });

        watch(answers, () => {
            emit('update-answers', answers.value);
        });

        onMounted(initialize);

        const fetchQuestions = async () => {
            try {
                const response = await qsApi.questionsGet()
                if (response.data.data != null) {
                    let tp = response.data.data[props.qType]
                    let resAnswers = tp.map((mbtiQestion: DefsMBTIQuestion) => {
                        return {
                            id: mbtiQestion.id,
                            question_id: mbtiQestion.id || undefined,
                            question: mbtiQestion.question || undefined,
                            score: 1,
                        }
                    });
                    answers.value = resAnswers as unknown as DefsMBTIAnswerData[]
                }
            } catch (error) {
                console.error('Failed to fetch questions:', error);
            }
        }


        const fetchAnswersByUserId = async (userID: string) => {
            try {
                const response = await userApi.usersUserIDAnswersGet(userID)
                if (response.data.data != null) {
                    let an = response.data.data[props.qType]
                    let resAnswers: DefsMBTIAnswerData[] = an.map((answer: DefsMBTIAnswerData) => {
                        return {
                            id: answer.id,
                            question_id: answer.question_id,
                            question: answer.question?.question,
                            score: answer.score
                        }
                    });
                    answers.value = resAnswers
                    return true
                } 
                return false
            } catch (error) {
                return false
            }
        };

        async function initialize() {
            let res = await fetchAnswersByUserId(props.userId as string)
            if(!res) {
                fetchQuestions();   
            }
        }

        function editItem(answer: DefsMBTIAnswerData) {
            editedIndex.value = answers.value.indexOf(answer);
            editedItem.value = Object.assign({}, answer);
            dialog.value = true;
        }

        function close() {
            console.log("close")
            dialog.value = false;
            editedItem.value = Object.assign({}, defaultItem);
            editedIndex.value = -1;
        }

        async function closeDelete() {
            dialogDelete.value = false;
            editedItem.value = Object.assign({}, defaultItem);
            editedIndex.value = -1;
        }

        async function save() {
            if (editedIndex.value > -1) {
                Object.assign(answers.value[editedIndex.value], editedItem.value);
            }
            close();
        }

        return {
            initialize,
            dialog,
            dialogDelete,
            headers,
            questions,
            answers,
            editedIndex,
            editedItem,
            editItem,
            close,
            closeDelete,
            save,
            fetchQuestions,
            props,
            scores,
        }
    }
}

