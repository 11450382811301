// export const WS_URL = "http://127.0.0.1:7020";
// export const WS_URL = "http://api.toequal.com:7020";
// export const BASE_PATH = "http://api.toequal.com/api/v1".replace(/\/+$/, "");

export const WS_URL = "rest.toequal.com";
// export const WS_URL = "127.0.0.1:7020";

export const NO_RECORDS = 'no records';

export interface Header {
    title: string;
    key: string;
    sortable?: boolean;
}

export enum EntityType {
    Interest = "Interest",
    Profession = 'Profession',
    Location = "Location",
    SexualOrientation = 'Sexual Orientation',
    Gender = 'Gender',
    Race = 'Race',
    Hobby = 'Hobby',
    EyeColor = 'Eye Color',
    BodyType = 'Body Type',
    Education = 'Education',
}

export interface EntityProps {
    entity: any[];
    headers: Header[];
    mode: string;
    name: string;
}

export interface Answer {
    question_id: string;
    score: number;
}

export interface Answers {
    user_id: string;
    mbti: Answer[];
    attachment: Answer[];
}

// export interface Question {
//     id?: string;
//     type: string;
//     question: string;
//     dimension: string;
//     direction: number;
//     meaning: string;
// }

// export interface TestQuestions {
//     mbti: Question[]
//     attachment: Question[]
// }

// export interface User {
//     id?: string;
//     firstName: string;
//     lastName: string;
//     email: string;
//     fullName: string;
// }

// export interface EntityI {
//     id: string;
//     text: () => string;
// }

// // export class Entitiy implements EntitiesI {
// //     id!: number;
// //     getId() { return this.id }
// // }

// export interface Profession extends EntityI {
//     jobTitle: string;
// }

// export interface SexualOrientation extends EntityI {
//     orientation: string;
// }

// export interface Gender extends EntityI {
//     gender: string;
// }

// export interface Race extends EntityI {
//     ethnicity: string;
// }

// export interface Interest extends EntityI {
//     interest: string;
// }

// export interface Hobby extends EntityI {
//     hobby: string;
// }

// export interface EyeColor extends EntityI {
//     color: string;
// }

// export interface BodyType extends EntityI {
//     type: string;
// }

// export interface Education extends EntityI {
//     education: string;
//     degree: string;
// }

// export interface ELocation extends EntityI {
//     city: string;
//     country: string;
//     state: string;
// }

// export interface UserProfileData {
//     id?: string;
//     userId?: string;
//     nickname: string;
//     age: number;
//     imageUrls?: string[];
//     height: number;
//     income: number;
//     smoking: boolean;
//     drinking: boolean;
//     haveChildren: boolean;
//     wantChildren: boolean;
//     aboutMe: string;
//     likePets: boolean;
//     havePets: boolean;
//     education?: Education;
//     gender?: Gender;
//     eyeColor?: EyeColor;
//     birthDate?: Date;
//     bodyType?: BodyType;
//     location?: ELocation;
//     profession?: Profession;
//     sexualOrientation?: SexualOrientation;
//     interests?: Interest[];
//     hobbies?: Hobby[];
//     races?: Race[];
// }

// export interface OK {
//     message: string
// }

// export interface OKWithID {
//     id: string
// }

// export interface EResponse<T> {
//     error?: HTTPError;
//     data: T;
//     message: string;
//     code: number;
// }

// export interface HTTPError {
//     code: number;
//     message: string;
// }

// export interface Question {
//     id?: string;
//     type: string;
//     question: string;
//     dimension: string;
//     direction: number;
//     meaning: string;
// }

// export interface TestQuestions {
//     mbti: Question[]
//     attachment: Question[]
// }

// export interface Answer {
//     id?: string;
//     question_id: string;
//     question?: string;
//     score: number;
// }

// export interface TestAnswer {
//     id?: string;
//     userId: string;
//     mbti: Answer[];
//     attachment: Answer[];
// }

// export interface AnswerRes {
//     id?: string;
//     question_id: string;
//     question: Question;
//     score: number;
// }


// export interface AgePref {
//     minAge: number;
//     maxAge: number;
//     strong: boolean;
// }

// export interface HeightPref {
//     minHeight: number;
//     maxHeight: number;
//     metrics: string;
//     strong: boolean;
// }

// export interface SexualOrientationReq {
//     sexualOrientationID?: string;
//     strong: boolean;
// }

// export interface SexualOrientationPref {
//     sexualOrientationID?: string;
//     strong: boolean;
//     sexualOrientationList?: SexualOrientation[];
// }

// export interface IncomePref {
//     minIncome: number;
//     maxIncome: number;
//     strong: boolean;
// }

// export interface SmokingPref {
//     smoking: boolean;
//     strong: boolean;
// }

// export interface BodyTypeReq {
//     bodyTypeID?: string;
//     strong: boolean;
// }

// export interface BodyTypePref {
//     bodyTypeID?: string;
//     bodyTypeList?: BodyType[];
//     strong: boolean;
// }

// export interface EyeColorReq {
//     eyeColorID?: string;
//     strong: boolean;
// }

// export interface EyeColorPref {
//     eyeColorID?: string;
//     eyeColorList?: EyeColor[];
//     strong: boolean;
// }

// export interface DrinkingPref {
//     drinking: boolean;
//     strong: boolean;
// }

// export interface ChildrenPref {
//     haveChildren: boolean;
//     haveChildrenStrong: boolean;
//     wantChildren: boolean;
//     wantChildrenStrong: boolean;
// }

// export interface EducationReq {
//     educationId?: string;
//     strong: boolean;
// }

// export interface EducationPref {
//     educationID?: string;
//     educationList?: Education[];
//     strong: boolean;
// }

// export interface LocationReq {
//     locationID?: string;
//     strong: boolean;
// }

// export interface LocationPref {
//     locationID?: string;
//     locationList?: ELocation[];
//     strong: boolean;
// }

// export interface ProfessionReq {
//     professionID?: string; 
//     strong: boolean;
// }

// export interface ProfessionPref {
//     professionID?: string; 
//     professionList?: Profession[];
//     strong: boolean;
// }

// export interface ZodiacSignPref {
//     zodiacSigns: string[];
//     strong: boolean;
// }

// export interface PetsPref {
//     likePets: boolean;
//     likePetsStrong: boolean;
//     havePets: boolean;
//     havePetsStrong: boolean;
// }

// export interface UserPreferencesReq {
//     userId: string;
//     age: AgePref;
//     sexualOrientation: SexualOrientationReq;
//     height: HeightPref;
//     income: IncomePref;
//     smoking: SmokingPref;
//     bodyType: BodyTypeReq;
//     eyeColor: EyeColorReq;
//     drinking: DrinkingPref;
//     children: ChildrenPref;
//     education: EducationPref;
//     location: LocationReq;
//     profession: ProfessionReq;
//     zodiacSign: ZodiacSignPref;
//     pets: PetsPref;
// }

// export interface UserPreferences {
//     id?: string;
//     userId: string;
//     age: AgePref;
//     sexualOrientation: SexualOrientationPref;
//     height: HeightPref;
//     income: IncomePref;
//     smoking: SmokingPref;
//     bodyType: BodyTypePref;
//     eyeColor: EyeColorPref;
//     drinking: DrinkingPref;
//     children: ChildrenPref;
//     education: EducationPref;
//     location: LocationPref;
//     profession: ProfessionPref;
//     zodiacSign: ZodiacSignPref;
//     pets: PetsPref;
// }

// export enum ThemeType {
//     Green = "green",
//     Orange = 'orange',
//     Equal  = 'equal',
// }

// export enum LanguageType {
//     English = "english",
// }

// export interface UserSettings {
//     id?: string;
//     userId:string;
//     theme: ThemeType;
//     language: LanguageType;
//     showStatus:boolean;
//     showLocation:boolean;
//     showAge:boolean;
//     showIncome:boolean;
//     showHeight:boolean;
//     showProfession:boolean;
//     showEducation:boolean;
//     showZodiacSign:boolean;
//     showBodyType:boolean;
//     showEyeColor:boolean;
//     showSmoking:boolean;
//     showDrinking:boolean;
//     showChildren:boolean;
//     showPets:boolean;
//     showSexualOrientation:boolean;
// }
