
import { eventBus } from './eventBus';

import { useRouter } from 'vue-router';
import { useAuth } from './useAuth';
export default {
  name: 'App',

  setup() {
    const router = useRouter();
    const { menuItemText, menuItemIcon, updateLoginState, isLoggedIn } = useAuth();
    function toggleLoginState() {
      updateLoginState(!isLoggedIn.value);
    }

    function logout() {
      localStorage.removeItem('authToken');
      eventBus.emit('login-status-changed', false);
      router.push('/login');
    }

    function data() {
      return {
        drawer: true,
        dark: false,
      };
    }

    return {
      data,
      logout,
      isLoggedIn,
      menuItemText,
      menuItemIcon,
      toggleLoginState
    };
  }
}

