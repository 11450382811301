

import { onMounted, reactive } from 'vue';
import QTmpl, {Question, TestQuestions} from '@/components/QTmpl.vue';


export default {
  components: {
    QTmpl
  },
  setup() {

    const notification = reactive({
      show: false,
      message: '',
      color: 'success'
    });

    onMounted(initialize);

    const totalQuestions = reactive({
      mbti: [] as Question[],
      attachment: [] as Question[],
    });

    function updateQuestions(newQuestions: Question[], qType: 'mbti' | 'attachment') {
      totalQuestions[qType] = newQuestions;
    }

    function initialize() {
      console.log("initialize")
    }

    async function saveQuestions() {
      console.log("save")
      // const req = cleenQustions(totalQuestions)
      // if (req.attachment.length === 0 || req.attachment.length === 0) {
      //   notification.color = 'error';
      //   notification.message = 'Questions cannot be empty';
      //   notification.show = true;
      //   hideNotification()
      //   return
      // }
      // try {
      //   let resp = await axios.post<EResponse<OK>>(URL + 'questions', req);
      //   console.log(resp)
      //   notification.message = 'Questions was saved successfully!';
      //   notification.color = 'success';
      // } catch (error) {
      //   const axiosError = error as AxiosError<EResponse<unknown>>;
      //   if (axiosError && axiosError.response) {
      //     notification.color = 'error';
      //     notification.message = axiosError.response.data.error?.message ?? 'An error occurred';
      //   } else {
      //     notification.message = 'An unexpected error occurred';
      //   }
      // }
      notification.show = true;
      hideNotification()
    }

    const hideNotification = () => {
      setTimeout(() => {
        notification.show = false;
      }, 3000);
    };

    return {
      notification,
      // ent,
      initialize,
      QTmpl,
      saveQuestions,
      updateQuestions,
    }
  }
}

