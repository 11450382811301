
// import { GlobalError, NoRecordsError } from '@/errors';
// import axios from 'axios';
// import { EResponse, OKWithID, UserPreferencesReq, UserProfileData, UserSettings } from '@/types';

// export async function getData<T>(endpoint: string): Promise<T> {
//     try {
//         const response = await axios.get<EResponse<T>>(endpoint);
//         if (response.data.error) {
//             throw new GlobalError(response.data.error.message, response.data.error.code);
//         }
//         if (response.data.message == "no records found") {
//             throw new NoRecordsError(response.data.message, response.data.code);
//         }
//         return response.data.data;
//     } catch (error) {
//         if (error instanceof NoRecordsError) {
//             console.log("No records found")            
//         } else if (error instanceof GlobalError) {
//             console.error(`Error fetching data: ${error.message} (code: ${error.code})`);
//         } else {
//             console.error(`An unexpected error occurred: ${error}`);
//         }
//         throw error;
//     }
// }

// export async function getDataList<T>(endpoint: string): Promise<T[]> {
//     try {
//         const response = await axios.get<EResponse<T[]>>(endpoint);
//         if (response.data.error) {
//             throw new GlobalError(response.data.error.message, response.data.error.code);
//         }
//         return response.data.data;
//     } catch (error) {
//         if (error instanceof GlobalError) {
//             console.error(`Error fetching data: ${error.message} (code: ${error.code})`);
//         } else {
//             console.error(`An unexpected error occurred: ${error}`);
//         }
//         throw error;
//     }
// }

// export async function save<T>(endpoint: string, data: T): Promise<OKWithID> {
//     try {
//         const response = await axios.post<EResponse<OKWithID>>(endpoint, data);
//         if (response.data.error) {
//             throw new GlobalError(response.data.error.message, response.data.error.code);
//         }
//         return response.data.data;
//     } catch (error) {
//         if (error instanceof GlobalError) {
//             console.error(`Error post data: ${error.message} (code: ${error.code})`);
//         } else {
//             console.error(`An unexpected error occurred: ${error}`);
//         }
//         throw error;
//     }
// }

// export async function saveUserSettings(endpoint: string, data: UserSettings): Promise<OKWithID> {
//     return save<UserSettings>(endpoint, data);
// }

// export async function saveUserProfile(endpoint: string, data: UserProfileData): Promise<OKWithID> {
//     return save<UserProfileData>(endpoint, data);
//     // try {
//     //     const response = await axios.post<EResponse<OKWithID>>(endpoint, data);
//     //     if (response.data.error) {
//     //         throw new GlobalError(response.data.error.message, response.data.error.code);
//     //     }
//     //     return response.data.data;
//     // } catch (error) {
//     //     if (error instanceof GlobalError) {
//     //         console.error(`Error post data: ${error.message} (code: ${error.code})`);
//     //     } else {
//     //         console.error(`An unexpected error occurred: ${error}`);
//     //     }
//     //     throw error;
//     // }
// }

// export async function saveUserPreferences(endpoint: string, data: UserPreferencesReq): Promise<OKWithID> {
//     return save<UserPreferencesReq>(endpoint, data);
//     // try {
//     //     const response = await axios.post<EResponse<OKWithID>>(endpoint, data);
//     //     if (response.data.error) {
//     //         throw new GlobalError(response.data.error.message, response.data.error.code);
//     //     }
//     //     return response.data.data;
//     // } catch (error) {
//     //     if (error instanceof GlobalError) {
//     //         console.error(`Error post data: ${error.message} (code: ${error.code})`);
//     //     } else {
//     //         console.error(`An unexpected error occurred: ${error}`);
//     //     }
//     //     throw error;
//     // }
// }

export const formatKey = (key: string) => {
    return key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) { return str.toUpperCase(); })
}

export function mapIdsToObjects<T extends { id: string }>(ids: string[], objects: T[]): T[] {
    return ids.map(id => objects.find(obj => obj.id === id)!);
}

export function mapIdToObject<T extends { id: string | undefined }>(id: string | undefined, objects: T[] | undefined): T | undefined {
    return id && objects ? objects.find(obj => obj.id === id) : undefined;
}

export function mapObjectsToIds<T extends { id?: string }>(objects: T[] | undefined): string[] {
    return objects?.map(obj => obj.id).filter(id => id !== undefined) as string[];
}
