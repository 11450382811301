

import { onMounted } from 'vue';
import { Entities } from '@/classes/entities';
import EntityTmpl from '@/components/EntityTmpl.vue';
import { EntityType } from '@/types';


export default {
  components: {
    EntityTmpl
  },
  setup() {
    const ent = new Entities();

    onMounted(initialize);

    function initialize() {
      console.log("initialize")
      ent.getEntities()
   }
    return {
      ent,
      initialize,
      EntityType,
    }
  }
}

